import * as Yup from "yup";

export const RegisterSchema = Yup.object({
  first_name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter first name as per Passport")
    .max(255),
  last_name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter last name as per Passport")
    .max(255),
  email_id: Yup.string()
    .required("Please enter email")
    .email("Please enter valid email")
    .max(255),
  date_of_birth: Yup.date().required("Please provide date of birth"),
  nationality_id: Yup.mixed().required("Please select nationality"),
  upload_document: Yup.mixed().required("Please select resume"),
  phone_code_id: Yup.mixed().required("Please select country"),
  phone_number: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter mobile number")
    .max(20),

  qualification: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter qualification")
    .max(255),
  address: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter address")
    .max(255),
});
