// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import { Button, DialogContent } from "@mui/material";
import MDInput from "components/MDInput";
import { fetchData } from "utils/clientFunctions";
import { postData, doUpload } from "utils/clientFunctions";
import { useToasts } from "react-toast-notifications";

import { Link, useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";
import { ErrorMessage, Field, Form, Formik } from "formik";

import { createStaffSchema, updateStaffSchema } from "../components/schemas";
import { CardTopBoxBack } from "layouts/commonComponents/cardTopBoxBack";
import { updateData } from "utils/clientFunctions";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { UpdateState } from "./updatePermissionState";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
function StaffData() {
  let { id } = useParams();

  const { addToast } = useToasts();
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  const reduxPermissions = useSelector(selectPermissions);
  if (id) {
    if (
      !(
        reduxPermissions?.user_id === 1 ||
        reduxPermissions?.permission?.includes("usrEdit")
      )
    ) {
      navigate("/staffs");
    }
  } else {
    if (
      !(
        reduxPermissions?.user_id === 1 ||
        reduxPermissions?.permission?.includes("usrCreate")
      )
    ) {
      navigate("/staffs");
    }
  }
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [permissions, setPermissions] = useState({
    // Dashboard Recruiting Agency
    dshRaAll: "Off",
    dshRaView: "Off",

    // Dashboard Company
    dshCmpAll: "Off",
    dshCmpView: "Off",

    // Dashboard Applicants
    dshAppAll: "Off",
    dshAppView: "Off",

    // Dashboard Accounts
    dshAccAll: "Off",
    dshAccView: "Off",

    // Dashboard Upcoming meetings
    dshUpMetAll: "Off",
    dshUpMetView: "Off",

    // Dashboard Upcoming Tasks
    dshUpTskAll: "Off",
    dshUpTskView: "Off",

    //Recruiting Agency
    raAll: "Off",
    raView: "Off",
    raCreate: "Off",
    raEdit: "Off",
    raDelete: "Off",

    //Company
    cmpAll: "Off",
    cmpView: "Off",
    cmpCreate: "Off",
    cmpEdit: "Off",
    cmpDelete: "Off",

    //Applicants
    appAll: "Off",
    appView: "Off",
    appCreate: "Off",
    appEdit: "Off",
    appDelete: "Off",

    //Leads
    ledAll: "Off",
    ledView: "Off",
    ledCreate: "Off",
    ledEdit: "Off",
    ledDelete: "Off",

    //Accounts
    accAll: "Off",
    accView: "Off",
    accCreate: "Off",
    accEdit: "Off",
    accDelete: "Off",

    //Meetings
    metAll: "Off",
    metView: "Off",
    metCreate: "Off",
    metEdit: "Off",
    metDelete: "Off",

    //Deals
    delAll: "Off",
    delView: "Off",
    delCreate: "Off",
    delEdit: "Off",
    delDelete: "Off",

    //Call Report
    clrAll: "Off",
    clrView: "Off",
    clrCreate: "Off",
    clrEdit: "Off",
    clrDelete: "Off",

    //Booking
    bokAll: "Off",
    bokView: "Off",
    bokCreate: "Off",
    bokEdit: "Off",
    bokDelete: "Off",

    //Interview
    intAll: "Off",
    intView: "Off",
    intCreate: "Off",
    intEdit: "Off",
    intDelete: "Off",

    //Attestation
    attAll: "Off",
    attView: "Off",
    attCreate: "Off",
    attEdit: "Off",
    attDelete: "Off",

    //Deployment
    depAll: "Off",
    depView: "Off",
    depCreate: "Off",
    depEdit: "Off",
    depDelete: "Off",

    //Tax
    taxAll: "Off",
    taxView: "Off",
    taxCreate: "Off",
    taxEdit: "Off",
    taxDelete: "Off",

    //Tasks
    tskAll: "Off",
    tskView: "Off",
    tskCreate: "Off",
    tskEdit: "Off",
    tskDelete: "Off",

    //Users
    usrAll: "Off",
    usrView: "Off",
    usrCreate: "Off",
    usrEdit: "Off",
    usrDelete: "Off",

    //Settings
    setAll: "Off",
    setView: "Off",
    setCreate: "Off",
    setEdit: "Off",
    setDelete: "Off",

    // Lock
    lockAll: "Off",
    lockView: "Off",
    lockCreate: "Off",
  });

  UpdateState(permissions, setPermissions);

  const [formData, setFormData] = useState({
    user_type: "",
    first_name: "",
    last_name: "",
    mobile_number: "",
    email_id: "",
    profile_pic: "",
    password: "",
  });

  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };
  const updatePermission = (updatePerm, value) => {
    return updatePerm?.includes(value) ? "On" : "Off";
  };
  //Get country info
  useEffect(() => {
    if (id == 1) {
      navigate("/staffs", { replace: true });
    }
    if (id) {
      const updateUrl = `user/${id}`;
      getFetchData(updateUrl).then((res) => {
        let {
          first_name,
          last_name,
          mobile_number,
          email_id,
          user_type,
          profile_pic,
          permission,
        } = res.data;

        let upPerm = JSON.parse(permission);

        setPermissions({
          // Dashboard Recruiting Agency
          dshRaAll: updatePermission(upPerm, "dshRaAll"),
          dshRaView: updatePermission(upPerm, "dshRaView"),

          // Dashboard Company
          dshCmpAll: updatePermission(upPerm, "dshCmpAll"),
          dshCmpView: updatePermission(upPerm, "dshCmpView"),

          // Dashboard Applicants
          dshAppAll: updatePermission(upPerm, "dshAppAll"),
          dshAppView: updatePermission(upPerm, "dshAppView"),

          // Dashboard Accounts
          dshAccAll: updatePermission(upPerm, "dshAccAll"),
          dshAccView: updatePermission(upPerm, "dshAccView"),

          // Dashboard Upcoming meetings
          dshUpMetAll: updatePermission(upPerm, "dshUpMetAll"),
          dshUpMetView: updatePermission(upPerm, "dshUpMetView"),

          // Dashboard Upcoming Tasks
          dshUpTskAll: updatePermission(upPerm, "dshUpTskAll"),
          dshUpTskView: updatePermission(upPerm, "dshUpTskView"),

          //Recruiting Agency
          raAll: updatePermission(upPerm, "raAll"),
          raView: updatePermission(upPerm, "raView"),
          raCreate: updatePermission(upPerm, "raCreate"),
          raEdit: updatePermission(upPerm, "raEdit"),
          raDelete: updatePermission(upPerm, "raDelete"),

          //Company
          cmpAll: updatePermission(upPerm, "cmpAll"),
          cmpView: updatePermission(upPerm, "cmpView"),
          cmpCreate: updatePermission(upPerm, "cmpCreate"),
          cmpEdit: updatePermission(upPerm, "cmpEdit"),
          cmpDelete: updatePermission(upPerm, "cmpDelete"),

          //Applicants
          appAll: updatePermission(upPerm, "appAll"),
          appView: updatePermission(upPerm, "appView"),
          appCreate: updatePermission(upPerm, "appCreate"),
          appEdit: updatePermission(upPerm, "appEdit"),
          appDelete: updatePermission(upPerm, "appDelete"),

          //Leads
          ledAll: updatePermission(upPerm, "ledAll"),
          ledView: updatePermission(upPerm, "ledView"),
          ledCreate: updatePermission(upPerm, "ledCreate"),
          ledEdit: updatePermission(upPerm, "ledEdit"),
          ledDelete: updatePermission(upPerm, "ledDelete"),

          //Accounts
          accAll: updatePermission(upPerm, "accAll"),
          accView: updatePermission(upPerm, "accView"),
          accCreate: updatePermission(upPerm, "accCreate"),
          accEdit: updatePermission(upPerm, "accEdit"),
          accDelete: updatePermission(upPerm, "accDelete"),

          //Meetings
          metAll: updatePermission(upPerm, "metAll"),
          metView: updatePermission(upPerm, "metView"),
          metCreate: updatePermission(upPerm, "metCreate"),
          metEdit: updatePermission(upPerm, "metEdit"),
          metDelete: updatePermission(upPerm, "metDelete"),

          //Deals
          delAll: updatePermission(upPerm, "delAll"),
          delView: updatePermission(upPerm, "delView"),
          delCreate: updatePermission(upPerm, "delCreate"),
          delEdit: updatePermission(upPerm, "delEdit"),
          delDelete: updatePermission(upPerm, "delDelete"),

          //Call Report
          clrAll: updatePermission(upPerm, "clrAll"),
          clrView: updatePermission(upPerm, "clrView"),
          clrCreate: updatePermission(upPerm, "clrCreate"),
          clrEdit: updatePermission(upPerm, "clrEdit"),
          clrDelete: updatePermission(upPerm, "clrDelete"),

          //Booking
          bokAll: updatePermission(upPerm, "bokAll"),
          bokView: updatePermission(upPerm, "bokView"),
          bokCreate: updatePermission(upPerm, "bokCreate"),
          bokEdit: updatePermission(upPerm, "bokEdit"),
          bokDelete: updatePermission(upPerm, "bokDelete"),

          //Interview
          intAll: updatePermission(upPerm, "intAll"),
          intView: updatePermission(upPerm, "intView"),
          intCreate: updatePermission(upPerm, "intCreate"),
          intEdit: updatePermission(upPerm, "intEdit"),
          intDelete: updatePermission(upPerm, "intDelete"),

          //Attestation
          attAll: updatePermission(upPerm, "attAll"),
          attView: updatePermission(upPerm, "attView"),
          attCreate: updatePermission(upPerm, "attCreate"),
          attEdit: updatePermission(upPerm, "attEdit"),
          attDelete: updatePermission(upPerm, "attDelete"),

          //Deployment
          depAll: updatePermission(upPerm, "depAll"),
          depView: updatePermission(upPerm, "depView"),
          depCreate: updatePermission(upPerm, "depCreate"),
          depEdit: updatePermission(upPerm, "depEdit"),
          depDelete: updatePermission(upPerm, "depDelete"),

          //Tax
          taxAll: updatePermission(upPerm, "taxAll"),
          taxView: updatePermission(upPerm, "taxView"),
          taxCreate: updatePermission(upPerm, "taxCreate"),
          taxEdit: updatePermission(upPerm, "taxEdit"),
          taxDelete: updatePermission(upPerm, "taxDelete"),

          //Tasks
          tskAll: updatePermission(upPerm, "tskAll"),
          tskView: updatePermission(upPerm, "tskView"),
          tskCreate: updatePermission(upPerm, "tskCreate"),
          tskEdit: updatePermission(upPerm, "tskEdit"),
          tskDelete: updatePermission(upPerm, "tskDelete"),

          //Users
          usrAll: updatePermission(upPerm, "usrAll"),
          usrView: updatePermission(upPerm, "usrView"),
          usrCreate: updatePermission(upPerm, "usrCreate"),
          usrEdit: updatePermission(upPerm, "usrEdit"),
          usrDelete: updatePermission(upPerm, "usrDelete"),

          //Settings
          setAll: updatePermission(upPerm, "setAll"),
          setView: updatePermission(upPerm, "setView"),
          setCreate: updatePermission(upPerm, "setCreate"),
          setEdit: updatePermission(upPerm, "setEdit"),
          setDelete: updatePermission(upPerm, "setDelete"),

          // lock
          lockAll: updatePermission(upPerm, "lockAll"),
          lockView: updatePermission(upPerm, "lockView"),
          lockCreate: updatePermission(upPerm, "lockCreate"),
        });

        let updateUserType = userTypeData?.filter(
          (type) => type.value === user_type
        );

        setFormData({
          first_name,
          last_name,
          mobile_number,
          email_id,
          profile_pic: profile_pic || "",
          user_type: updateUserType && updateUserType[0],
        });
      });
    }
  }, []);

  const handleFormSubmit = async (data, resetForm) => {
    //Delete emai id from update data
    if (id) {
      delete data.email_id;
    }
    //Collect final Data
    let submitData = {};
    submitData = data;
    submitData.user_type = data.user_type?.value;

    //Filter and Update the permission
    let newPermissions = [];
    for (var key in permissions) {
      if (permissions[key] === "On") {
        newPermissions.push(key);
      }
    }
    submitData.permission = newPermissions;

    const url = `user`;
    const response = id
      ? await updateData(`user/${id}`, submitData)
      : await postData(url, submitData);
    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      addToast("User has been updated successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(-1);
    }
  };

  const onChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setFormData({ ...formData, [name]: val });
  };

  const handleProfileChange = async (e) => {
    const { name, value } = e.target;
    let val = e.target.files[0];
    let uploadFile = { file: val };
    const url = `upload_any/do_upload`;
    const response = await doUpload(url, uploadFile);
    if (response.status && response.uploadedFile) {
      let filePath = response.uploadedFile.path;
      setFormData((values) => ({ ...values, [name]: filePath }));
    } else {
      setFormData((values) => ({ ...values, [name]: "" }));
    }
  };

  const ErrorDisp = (name) => {
    console.log(errors?.[name]); // Use `errors?.[name]` to access the dynamic field name
    return (
      errors[name] &&
      touched[name] && ( // Use `errors[name]` instead of `errors.name`
        <p className="formError">{errors[name]}</p>
      )
    );
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: id ? updateStaffSchema : createStaffSchema,
    onSubmit: (values, { resetForm }) => {
      handleFormSubmit(values, resetForm);
    },
  });

  const userTypeData = [
    { label: "Staff", value: "staff" },
    { label: "Partner", value: "partner" },
  ];

  const handlePermissionChange = (e) => {
    let { name, value } = e.target;
    const onchangeVal = { ...permissions };
    onchangeVal[name] = value === "On" ? "Off" : "On";
    setPermissions(onchangeVal);
  };

  const handleChecked = (value) => {
    return value === "On" ? true : false;
  };

  const Switcher = ({ name, value, disabled }) => {
    return (
      <Switch
        checked={handleChecked(value)}
        name={name}
        value={value}
        disabled={disabled}
        onChange={(e) => handlePermissionChange(e)}
      />
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Formik
          initialValues={formData}
          enableReinitialze={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <CardTopBoxBack title={id ? "Update User" : "New User"} />
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.user_type}
                                  size="small"
                                  name="user_type"
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setFieldValue("user_type", newValue);
                                    } else {
                                      setFieldValue("user_type", "");
                                      setFieldError(
                                        "user_type",
                                        "Please select user type"
                                      );
                                    }
                                  }}
                                  options={userTypeData}
                                  getOptionLabel={(userTypeData) =>
                                    userTypeData.label || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="User Type"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("user_type")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="first_name"
                                  type="text"
                                  label="First Name"
                                  fullWidth
                                  value={values.first_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("first_name")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="last_name"
                                  type="text"
                                  label="Last Name"
                                  fullWidth
                                  value={values.last_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("last_name")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="mobile_number"
                                  type="tel"
                                  label="Mobile Number"
                                  fullWidth
                                  value={values.mobile_number}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("mobile_number")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="email_id"
                                  type="email"
                                  label="Email ID"
                                  fullWidth
                                  disabled={id ? true : false}
                                  value={values.email_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("email_id")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  margin: "0px 2px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  component="label"
                                  fullWidth
                                  color="success"
                                >
                                  Profile Pic
                                  <input
                                    type="file"
                                    name="profile_pic"
                                    accept="image/png, image/jpeg"
                                    hidden
                                    onChange={(e) => handleProfileChange(e)}
                                  />
                                </Button>
                                {values.profile_pic && (
                                  <>
                                    <Link
                                      to={`${process.env.REACT_APP_URI_PATH}${values.profile_pic}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="mdsViewFile"
                                    >
                                      <MDButton
                                        variant="gradient"
                                        color="primary"
                                        size="small"
                                        type="button"
                                      >
                                        View Pic
                                      </MDButton>
                                    </Link>
                                  </>
                                )}
                              </MDBox>
                            </Grid>
                            {!id && (
                              <Grid item xs={12} md={3}>
                                <MDBox mb={2}>
                                  <MDInput
                                    name="password"
                                    type="text"
                                    label="Password"
                                    fullWidth
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {ErrorDisp("password")}
                                </MDBox>
                              </Grid>
                            )}
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>

                {/* Start Permission */}
                <Card className="mt4rem">
                  <CardTopBoxBack
                    title={"Manage Permission"}
                    showBackButton={false}
                  />
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <TableContainer
                                sx={{ maxHeight: 420, borderRadius: 0 }}
                              >
                                <Table className="border" stickyHeader>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>
                                        <b>Menu</b>
                                      </TableCell>
                                      <TableCell>
                                        <b>All</b>
                                      </TableCell>
                                      <TableCell>
                                        <b>View</b>
                                      </TableCell>
                                      <TableCell>
                                        <b>Create</b>
                                      </TableCell>
                                      <TableCell>
                                        <b>Edit</b>
                                      </TableCell>
                                      <TableCell>
                                        <b>Delete</b>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                  <TableBody>
                                    {/* Dashboard */}
                                    <TableRow>
                                      <TableCell colSpan={6}>
                                        Dashboard
                                      </TableCell>
                                    </TableRow>

                                    {/* Dashboard Applicants */}
                                    <TableRow>
                                      <TableCell>----Applicants</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="dshAppAll"
                                          value={permissions.dshAppAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="dshAppView"
                                          value={permissions.dshAppView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                    </TableRow>

                                    {/* Dashboard Company */}
                                    <TableRow>
                                      <TableCell>----Company</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="dshCmpAll"
                                          value={permissions.dshCmpAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="dshCmpView"
                                          value={permissions.dshCmpView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switch disabled={true} />
                                      </TableCell>
                                      <TableCell>
                                        <Switch disabled={true} />
                                      </TableCell>
                                      <TableCell>
                                        <Switch disabled={true} />
                                      </TableCell>
                                    </TableRow>

                                    {/* Dashboard Recruiting Agency */}
                                    <TableRow>
                                      <TableCell>
                                        ----Recruiting Agency
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="dshRaAll"
                                          value={permissions.dshRaAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="dshRaView"
                                          value={permissions.dshRaView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                    </TableRow>

                                    {/* Dashboard Accounts */}
                                    <TableRow>
                                      <TableCell>----Accounts</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="dshAccAll"
                                          value={permissions.dshAccAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="dshAccView"
                                          value={permissions.dshAccView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                    </TableRow>

                                    {/* Dashboard Upcoming meetings */}
                                    <TableRow>
                                      <TableCell>
                                        ----Upcoming Meetings
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="dshUpMetAll"
                                          value={permissions.dshUpMetAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="dshUpMetView"
                                          value={permissions.dshUpMetView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                    </TableRow>

                                    {/* Dashboard Upcoming Tasks */}
                                    <TableRow>
                                      <TableCell>----Upcoming Tasks</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="dshUpTskAll"
                                          value={permissions.dshUpTskAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="dshUpTskView"
                                          value={permissions.dshUpTskView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher disabled={true} />
                                      </TableCell>
                                    </TableRow>

                                    {/* Applicants */}
                                    <TableRow>
                                      <TableCell>Applicants</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="appAll"
                                          value={permissions.appAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="appView"
                                          value={permissions.appView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="appCreate"
                                          value={permissions.appCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="appEdit"
                                          value={permissions.appEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="appDelete"
                                          value={permissions.appDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Company */}
                                    <TableRow>
                                      <TableCell>Company</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="cmpAll"
                                          value={permissions.cmpAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="cmpView"
                                          value={permissions.cmpView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="cmpCreate"
                                          value={permissions.cmpCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="cmpEdit"
                                          value={permissions.cmpEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="cmpDelete"
                                          value={permissions.cmpDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Recruiting Agency */}
                                    <TableRow>
                                      <TableCell>Recruiting Agency</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="raAll"
                                          value={permissions.raAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="raView"
                                          value={permissions.raView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="raCreate"
                                          value={permissions.raCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="raEdit"
                                          value={permissions.raEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="raDelete"
                                          value={permissions.raDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Call Report */}
                                    <TableRow>
                                      <TableCell>Call Report</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="clrAll"
                                          value={permissions.clrAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="clrView"
                                          value={permissions.clrView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="clrCreate"
                                          value={permissions.clrCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="clrEdit"
                                          value={permissions.clrEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="clrDelete"
                                          value={permissions.clrDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Leads */}
                                    <TableRow>
                                      <TableCell>Leads</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="ledAll"
                                          value={permissions.ledAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="ledView"
                                          value={permissions.ledView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="ledCreate"
                                          value={permissions.ledCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="ledEdit"
                                          value={permissions.ledEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="ledDelete"
                                          value={permissions.ledDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Meetings */}
                                    <TableRow>
                                      <TableCell>Meetings</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="metAll"
                                          value={permissions.metAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="metView"
                                          value={permissions.metView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="metCreate"
                                          value={permissions.metCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="metEdit"
                                          value={permissions.metEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="metDelete"
                                          value={permissions.metDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Deals */}
                                    <TableRow>
                                      <TableCell>Deals</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="delAll"
                                          value={permissions.delAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="delView"
                                          value={permissions.delView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="delCreate"
                                          value={permissions.delCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="delEdit"
                                          value={permissions.delEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="delDelete"
                                          value={permissions.delDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Attestation  */}
                                    <TableRow>
                                      <TableCell>Attestation</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="attAll"
                                          value={permissions.attAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="attView"
                                          value={permissions.attView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="attCreate"
                                          value={permissions.attCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="attEdit"
                                          value={permissions.attEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="attDelete"
                                          value={permissions.attDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Interview  */}
                                    <TableRow>
                                      <TableCell>Interview</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="intAll"
                                          value={permissions.intAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="intView"
                                          value={permissions.intView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="intCreate"
                                          value={permissions.intCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="intEdit"
                                          value={permissions.intEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="intDelete"
                                          value={permissions.intDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Booking  */}
                                    <TableRow>
                                      <TableCell>Booking</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="bokAll"
                                          value={permissions.bokAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="bokView"
                                          value={permissions.bokView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="bokCreate"
                                          value={permissions.bokCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="bokEdit"
                                          value={permissions.bokEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="bokDelete"
                                          value={permissions.bokDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Deployment  */}
                                    <TableRow>
                                      <TableCell>Deployment</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="depAll"
                                          value={permissions.depAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="depView"
                                          value={permissions.depView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="depCreate"
                                          value={permissions.depCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="depEdit"
                                          value={permissions.depEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="depDelete"
                                          value={permissions.depDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Tax  */}
                                    <TableRow>
                                      <TableCell>Tax</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="taxAll"
                                          value={permissions.taxAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="taxView"
                                          value={permissions.taxView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="taxCreate"
                                          value={permissions.taxCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="taxEdit"
                                          value={permissions.taxEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="taxDelete"
                                          value={permissions.taxDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Accounts */}
                                    <TableRow>
                                      <TableCell>Accounts</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="accAll"
                                          value={permissions.accAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="accView"
                                          value={permissions.accView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="accCreate"
                                          value={permissions.accCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="accEdit"
                                          value={permissions.accEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="accDelete"
                                          value={permissions.accDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Tasks */}
                                    <TableRow>
                                      <TableCell>Tasks</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="tskAll"
                                          value={permissions.tskAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="tskView"
                                          value={permissions.tskView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="tskCreate"
                                          value={permissions.tskCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="tskEdit"
                                          value={permissions.tskEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="tskDelete"
                                          value={permissions.tskDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Users  */}
                                    <TableRow>
                                      <TableCell>Users</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="usrAll"
                                          value={permissions.usrAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="usrView"
                                          value={permissions.usrView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="usrCreate"
                                          value={permissions.usrCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="usrEdit"
                                          value={permissions.usrEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="usrDelete"
                                          value={permissions.usrDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Settings */}
                                    <TableRow>
                                      <TableCell>Settings</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="setAll"
                                          value={permissions.setAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="setView"
                                          value={permissions.setView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="setCreate"
                                          value={permissions.setCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="setEdit"
                                          value={permissions.setEdit}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="setDelete"
                                          value={permissions.setDelete}
                                        />
                                      </TableCell>
                                    </TableRow>

                                    {/* Lock */}
                                    <TableRow>
                                      <TableCell>Lock</TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="lockAll"
                                          value={permissions.lockAll}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="lockView"
                                          value={permissions.lockView}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switcher
                                          name="lockCreate"
                                          value={permissions.lockCreate}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Switch disabled={true} />
                                      </TableCell>
                                      <TableCell>
                                        <Switch disabled={true} />
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>

            <MDBox mt={2} display="flex" alignItems="center">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                type="submit"
                className="saveButton"
              >
                Save
              </MDButton>
            </MDBox>
          </Form>
        </Formik>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default StaffData;
