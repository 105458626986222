// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState } from "react";
//import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
//import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import * as Yup from "yup";
import { updateData } from "utils/clientFunctions";

import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";

import { postData, doUpload } from "utils/clientFunctions";
import { ToastProvider, useToasts } from "react-toast-notifications";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import { useNavigate, useParams, Navigate, Link } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";

import { CreateApplicantSchema } from "../components/schemas";
import { removeDuplicates } from "utils/clientFunctions";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";

function CreateApplicant() {
  const { applicantId } = useParams();

  const { addToast } = useToasts();
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  if (applicantId) {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("appEdit")
      )
    ) {
      navigate("/applicant");
    }
  } else {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("appCreate")
      )
    ) {
      navigate("/applicant");
    }
  }
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [religionList, setReligionList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  //const [sourceLeadList, setSourceLeadList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const [applicantList, setApplicantList] = useState([]);
  const [raList, setRaList] = useState([]);
  const [usersList, setUserList] = useState([]);

  const [documents, setDocuments] = useState([]); //Manage multiple documents

  const [deploymentTypeList, setDeploymentTypeList] = useState([]);

  const leadOptions = [
    { label: "Recruiting agency", value: "ra" },
    { label: "Company", value: "company" },
    { label: "Applicant", value: "applicant" },
    { label: "Staff/Partner", value: "user" },
  ];

  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setRaList,
      setCompanyList,
      setApplicantList,
      setUserList,
    });
  };
  const [formData, setFormData] = useState({
    area: "",
    gender: "",
    image: "",
    salutation: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    passport_number: "",
    issue_country_id: "",
    issue_date: "",
    expiry_date: "",
    religion_id: "",
    mobile_code_id: "",
    mobile_number: "",
    email_id: "",
    whatsapp_code_id: null,
    whatsapp_number: "",
    emergency_code_id: null,
    emergency_contact_number: "",
    emergency_contact_name: "",
    emergency_contact_relation: "",
    company_id: "",
    position_id: "",
    source_of_lead_type: "",
    source_of_lead_id: "",
    address: "",
    landmark: "",
    postal_code: null,
    city: "",
    state: "",
    country_id: "",

    deployment_visa_issue_date: "",
    deployment_visa_expiry_date: "",
    deployment_date: "",
    deployment_type_id: "",
    deployment_fine_currency_id: "",
    deployment_fine_payment: "",
    deployment_remarks: "",
  });

  const getFetchData = async (url) => {
    return await fetchData(url);
  };

  //Get applicant, docs, religions, countries, position list and size of the company list
  useEffect(() => {
    if (applicantId) {
      const getApplicantUrl = `applicant/${applicantId}`;
      getFetchData(getApplicantUrl).then((res) => {
        if (res.data) {
          let {
            area,
            gender,
            image,
            salutation,
            first_name,
            last_name,
            date_of_birth,
            passport_number,
            issue_country_info,
            issue_date,
            expiry_date,
            mobile_number,
            email_id,
            whatsapp_number,
            emergency_contact_number,
            emergency_contact_name,
            emergency_contact_relation,
            address,
            landmark,
            postal_code,
            city,
            state,
            source_of_lead_type,
            mobile_code_info,
            whatsapp_code_info,
            emergency_code_info,
            country_info,
            religion_info,
            position_info,
            company_info,
            document_list,

            deployment_visa_issue_date,
            deployment_visa_expiry_date,
            deployment_date,
            deployment_type_id,
            deployment_fine_currency_id,
            deployment_fine_payment,
            deployment_remarks,

            deployment_type_info,
            deployment_fine_currency_info,
          } = res.data;

          let updateSalutation = { label: salutation, value: salutation };

          let updatedSourceOfType = leadOptions?.filter(
            (type) => type.value === source_of_lead_type
          );

          setFormData({
            area,
            gender,
            image,
            salutation: updateSalutation,
            first_name,
            last_name,
            email_id,
            date_of_birth,
            passport_number,
            issue_country_id: issue_country_info,
            issue_date,
            expiry_date,
            religion_id: religion_info,
            mobile_code_id: mobile_code_info,
            mobile_number,
            whatsapp_code_id: whatsapp_code_info,
            whatsapp_number,
            emergency_code_id: emergency_code_info,
            emergency_contact_number,
            emergency_contact_name,
            emergency_contact_relation,
            company_id: company_info,
            position_id: position_info,
            source_of_lead_type: updatedSourceOfType && updatedSourceOfType[0],
            source_of_lead_id: res.sourceOfLead,
            address,
            landmark,
            postal_code,
            city,
            state,
            country_id: country_info,

            deployment_visa_issue_date,
            deployment_visa_expiry_date,
            deployment_date,
            deployment_fine_payment,
            deployment_remarks,
            deployment_type_id: deployment_type_info,
            deployment_fine_currency_id: deployment_fine_currency_info,
          });

          let documentList = [];
          document_list?.map((document) => {
            const {
              document_name,
              issue_date,
              expiry_date,
              upload_document,
              issue_country_info,
            } = document;
            documentList.push({
              name: document_name,
              issue_country_id: issue_country_info,
              issue_date,
              expiry_date,
              document: upload_document,
            });
          });
          setDocuments(documentList);
        }
      });
    }

    //Get religions list
    const religionUrl = `setting/type/6`;
    getFetchData(religionUrl).then((res) => {
      let religionList = res.data;
      setReligionList(religionList);
    });

    //Get countries list
    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      let countryList = res.data;
      setCountryList(countryList);

      let currencyList = removeDuplicates(countryList, "currency_code"); //countryList?.filter((cr)=>cr.currency_code != "")
      setCurrencyList(currencyList);
    });

    //Get position list
    const positionUrl = `setting/type/4`;
    getFetchData(positionUrl).then((res) => {
      let positionList = res.data;
      setPositionList(positionList);
    });

    //Get source of lead list
    // const sourceLeadUrl = `setting/type/7`;
    // getFetchData(sourceLeadUrl).then((res) => {
    //   let leadList = res.data;
    //   setSourceLeadList(leadList);
    // });

    //Get company list
    const companyApiUrl = `company/list?page=1`;
    getFetchData(companyApiUrl).then((res) => {
      let companyDataList = res.data;
      setCompanyList(companyDataList);
    });

    const applicantUrl = `applicant/list?page=1`;
    getFetchData(applicantUrl).then((res) => {
      let applicantList = res.data;
      setApplicantList(applicantList);
    });

    const raUrl = `recruiting_agency/list?page=1`;
    getFetchData(raUrl).then((res) => {
      let raList = res.data;
      setRaList(raList);
    });

    const userUrl = `user/list?page=1`;
    getFetchData(userUrl).then((res) => {
      let userList = res.data;
      setUserList(userList);
    });

    //Get deploymentType List
    const deploymentTypeUrl = `setting/type/16`;
    getFetchData(deploymentTypeUrl).then((res) => {
      let deploymentTypeListResult = res.data;
      setDeploymentTypeList(deploymentTypeListResult);
    });
  }, []);

  const handleFormSubmit = async (data, resetForm) => {
    //Collect final Data
    let submitData = {};
    submitData = data;

    //Update below fields to send only ids
    submitData.salutation = data.salutation?.value;
    submitData.religion_id = data.religion_id?.setting_id;
    submitData.mobile_code_id = data.mobile_code_id?.country_id;
    submitData.whatsapp_code_id = data.whatsapp_code_id?.country_id;
    submitData.emergency_code_id = data.emergency_code_id?.country_id;
    submitData.company_id = data.company_id?.company_id;
    submitData.position_id = data.position_id?.setting_id;

    submitData.country_id = data.country_id?.country_id;
    submitData.issue_country_id = data.issue_country_id?.country_id;

    let source_of_lead_type = data.source_of_lead_type?.value;

    let source_of_lead_id = "";
    if (source_of_lead_type == "ra") {
      source_of_lead_id = data.source_of_lead_id?.ra_id;
    } else if (source_of_lead_type == "user") {
      source_of_lead_id = data.source_of_lead_id?.user_id;
    } else if (source_of_lead_type == "applicant") {
      source_of_lead_id = data.source_of_lead_id?.applicant_id;
    } else {
      source_of_lead_id = data.source_of_lead_id?.company_id;
    }
    submitData.source_of_lead_type = source_of_lead_type;
    submitData.source_of_lead_id = source_of_lead_id;

    submitData.deployment_date = data.deployment_date
      ? data.deployment_date
      : "";
    submitData.deployment_visa_expiry_date = data.deployment_visa_expiry_date
      ? data.deployment_visa_expiry_date
      : "";
    submitData.deployment_type_id = data.deployment_type_id?.setting_id;
    submitData.deployment_fine_currency_id =
      data.deployment_fine_currency_id?.country_id;

    //Convert documents into array
    let allDocuments = [];
    if (documents.length) {
      documents?.map((doc) => {
        const { name, issue_country_id, issue_date, expiry_date, document } =
          doc;
        if (document) {
          allDocuments.push({
            document_name: name,
            issue_country_id: issue_country_id?.country_id,
            issue_date: issue_date ? issue_date : null,
            expiry_date: expiry_date ? expiry_date : null,
            upload_document: document,
          });
        }
      });
    }
    submitData.documents = allDocuments; // Append multiple document data

    const applicantUrl = `applicant`;
    const response = applicantId
      ? await updateData(`${applicantUrl}/${applicantId}`, submitData)
      : await postData(applicantUrl, submitData);

    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      let successMessage = applicantId
        ? "Applicant has been updated successfully"
        : "New applicant has been created successfully";

      addToast(successMessage, {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(-1);
    }
  };

  const handleFileChange = async (event) => {
    let name = event.target.name;
    let val = event.target.files[0];
    let uploadFile = { file: val };
    const url = `upload_any/do_upload`;
    const response = await doUpload(url, uploadFile);
    if (response.status && response.uploadedFile) {
      let filePath = response.uploadedFile.path;
      //setFormData({ ...formData, [name]: filePath });
      formData.image = filePath;
      setFieldValue("image", filePath);
    } else {
      formData.image = "";
      setFieldValue("image", "");
      setFieldError("image", response.message);
    }
  };

  // START Manage Document
  const addMoreDocument = () => {
    setDocuments([...documents, {}]);
  };
  const handleDocumentChange = async (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...documents];
    if (name == "document") {
      let val = e.target.files[0];
      let uploadFile = { file: val };
      const url = `upload_any/do_upload`;
      const response = await doUpload(url, uploadFile);
      if (response.status && response.uploadedFile) {
        let filePath = response.uploadedFile.path;
        onchangeVal[i][name] = filePath;
      } else {
        onchangeVal[i][name] = "";
      }
    } else {
      onchangeVal[i][name] = value;
    }
    setDocuments(onchangeVal);
    console.log("documents", documents);
  };
  const handleDocumentDelete = (i) => {
    const deleteVal = [...documents];
    deleteVal.splice(i, 1);
    setDocuments(deleteVal);
  };
  // END Manage Document

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: CreateApplicantSchema,
    onSubmit: (values, action) => {
      handleFormSubmit(values, action);
    },
  });

  const salutationData = [
    { label: "Mr", value: "Mr" },
    { label: "Ms", value: "Ms" },
    { label: "Mrs", value: "Mrs" },
  ];

  const commaonError = (fieldName) => {
    return errors?.[fieldName] && touched?.[fieldName] ? (
      <p className="formError">{errors[fieldName]}</p>
    ) : null;
  };

  //console.log("1221values", values);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Formik
          initialValues={formData}
          enableReinitialze={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      {`${applicantId ? "Update" : "New"} `} Applicant
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      color="white"
                      size="small"
                    >
                      Back
                    </MDButton>
                  </MDBox>
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            {/* area/image/dob */}
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <label className="mdsInlineLabel">
                                  Select area
                                </label>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="area"
                                    name="area"
                                    value={values.area}
                                    onChange={handleChange}
                                  >
                                    <FormControlLabel
                                      value="Local Area"
                                      size="small"
                                      control={<Radio />}
                                      label="Local Area"
                                    />
                                    <FormControlLabel
                                      value="Overseas"
                                      size="small"
                                      control={<Radio />}
                                      label="Overseas"
                                    />
                                  </RadioGroup>
                                </FormControl>
                                {commaonError("area")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <label className="mdsInlineLabel">
                                  Select Gender
                                </label>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="gender"
                                    name="gender"
                                    value={values.gender}
                                    onChange={handleChange}
                                  >
                                    <FormControlLabel
                                      value="Male"
                                      size="small"
                                      control={<Radio />}
                                      label="Male"
                                    />
                                    <FormControlLabel
                                      value="Female"
                                      size="small"
                                      control={<Radio />}
                                      label="Female"
                                    />
                                  </RadioGroup>
                                </FormControl>
                                {commaonError("area")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  margin: "0px 2px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  component="label"
                                  fullWidth
                                  color="success"
                                >
                                  Upload Image
                                  <input
                                    type="file"
                                    name="image"
                                    accept="image/png, image/jpeg"
                                    hidden
                                    onChange={(e) => handleFileChange(e)}
                                  />
                                </Button>
                                {formData.image && (
                                  <Link
                                    to={
                                      process.env.REACT_APP_URI_PATH +
                                      formData.image
                                    }
                                    target="_blank"
                                  >
                                    <MDAvatar
                                      src={
                                        process.env.REACT_APP_URI_PATH +
                                        formData.image
                                      }
                                      name={formData.name}
                                      size="md"
                                      sx={{
                                        width: 56,
                                        height: 56,
                                        marginLeft: "10px",
                                      }}
                                      variant="rounded"
                                    />
                                  </Link>
                                )}
                              </MDBox>
                              {commaonError("image")}
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="date_of_birth"
                                  type="date"
                                  label="Date of Birth"
                                  fullWidth
                                  value={values.date_of_birth}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("date_of_birth")}
                              </MDBox>
                            </Grid>

                            {/* passport_number/issue_country_id/issue_date/expiry_date */}
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  name="passport_number"
                                  label="Passport Number"
                                  fullWidth
                                  value={values.passport_number}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("passport_number")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.issue_country_id}
                                  size="small"
                                  name="issue_country_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue(
                                          "issue_country_id",
                                          newValue
                                        )
                                      : setFieldValue("issue_country_id", "")
                                  }
                                  options={countryList}
                                  getOptionLabel={(countryList) =>
                                    countryList.name || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Issuing Country"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("issue_country_id")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="issue_date"
                                  type="date"
                                  label="Issue Date"
                                  fullWidth
                                  value={values.issue_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("issue_date")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="expiry_date"
                                  type="date"
                                  label="Expiry Date"
                                  fullWidth
                                  value={values.expiry_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("expiry_date")}
                              </MDBox>
                            </Grid>

                            {/* salutation/first_name/last_name */}
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.salutation}
                                  size="small"
                                  name="salutation"
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setFieldValue("salutation", newValue);
                                    } else {
                                      setFieldValue("salutation", "");
                                      setFieldError(
                                        "salutation",
                                        "Please select valid comapny size"
                                      );
                                    }
                                  }}
                                  options={salutationData}
                                  getOptionLabel={(salutationData) =>
                                    salutationData.label || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Salutation"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("salutation")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  name="first_name"
                                  label="First Name(Enter Name as per Passport)"
                                  fullWidth
                                  value={values.first_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("first_name")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  name="last_name"
                                  label="Last Name(Enter Name as per Passport)"
                                  fullWidth
                                  value={values.last_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("last_name")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>

                            {/* mobile_number/email_id/whatsapp_number/religion */}
                            <Grid item container xs={12} md={3} spacing={0}>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2} className="mdsAutocompleteGrid">
                                  <Autocomplete
                                    value={values.mobile_code_id}
                                    size="small"
                                    name="mobile_code_id"
                                    onChange={(event, newValue) =>
                                      newValue
                                        ? setFieldValue(
                                            "mobile_code_id",
                                            newValue
                                          )
                                        : setFieldValue("mobile_code_id", "")
                                    }
                                    options={countryList}
                                    getOptionLabel={(countryList) => {
                                      if (
                                        countryList.dial_code &&
                                        countryList.name
                                      ) {
                                        return (
                                          countryList.dial_code +
                                          " " +
                                          countryList.name
                                        );
                                      }
                                      return "";
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Country Code"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    )}
                                  />
                                  {commaonError("mobile_code_id")}
                                </MDBox>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2}>
                                  <MDInput
                                    name="mobile_number"
                                    type="text"
                                    label="Mobile Number"
                                    fullWidth
                                    value={values.mobile_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {commaonError("mobile_number")}
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Grid item container xs={12} md={3} spacing={0}>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2} className="mdsAutocompleteGrid">
                                  <Autocomplete
                                    value={values.whatsapp_code_id}
                                    size="small"
                                    name="whatsapp_code_id"
                                    onChange={(event, newValue) =>
                                      newValue
                                        ? setFieldValue(
                                            "whatsapp_code_id",
                                            newValue
                                          )
                                        : setFieldValue("whatsapp_code_id", "")
                                    }
                                    options={countryList}
                                    getOptionLabel={(countryList) => {
                                      if (
                                        countryList.dial_code &&
                                        countryList.name
                                      ) {
                                        return (
                                          countryList.dial_code +
                                          " " +
                                          countryList.name
                                        );
                                      }
                                      return "";
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Country Code"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    )}
                                  />
                                  {commaonError("whatsapp_code_id")}
                                </MDBox>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2}>
                                  <MDInput
                                    name="whatsapp_number"
                                    type="text"
                                    label="Whatsapp Number"
                                    fullWidth
                                    value={values.whatsapp_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {commaonError("whatsapp_number")}
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="email_id"
                                  type="email"
                                  label="E-mail Id"
                                  fullWidth
                                  value={values.email_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("email_id")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.religion_id}
                                  size="small"
                                  name="religion_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("religion_id", newValue)
                                      : setFieldValue("religion_id", "")
                                  }
                                  options={religionList}
                                  getOptionLabel={(religionList) =>
                                    religionList.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Religion"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("religion_id")}
                              </MDBox>
                            </Grid>

                            {/*emergency_contact_number/emergency_contact_name/emergency_contact_relation */}
                            <Grid item container xs={12} md={3} spacing={0}>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2} className="mdsAutocompleteGrid">
                                  <Autocomplete
                                    value={values.emergency_code_id}
                                    size="small"
                                    name="emergency_code_id"
                                    onChange={(event, newValue) =>
                                      newValue
                                        ? setFieldValue(
                                            "emergency_code_id",
                                            newValue
                                          )
                                        : setFieldValue("emergency_code_id", "")
                                    }
                                    options={countryList}
                                    getOptionLabel={(countryList) => {
                                      if (
                                        countryList.dial_code &&
                                        countryList.name
                                      ) {
                                        return (
                                          countryList.dial_code +
                                          " " +
                                          countryList.name
                                        );
                                      }
                                      return "";
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Country Code"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    )}
                                  />
                                  {commaonError("emergency_code_id")}
                                </MDBox>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2}>
                                  <MDInput
                                    name="emergency_contact_number"
                                    type="text"
                                    label="Emergency Contact Number"
                                    fullWidth
                                    value={values.emergency_contact_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {commaonError("emergency_contact_number")}
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="emergency_contact_name"
                                  type="text"
                                  label="Emergency Contact Name"
                                  fullWidth
                                  value={values.emergency_contact_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("emergency_contact_name")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="emergency_contact_relation"
                                  type="text"
                                  label="Emergency Contact Relation"
                                  fullWidth
                                  value={values.emergency_contact_relation}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("emergency_contact_relation")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>

                            {/* company_id/position_id/source_of_lead_type/source_of_lead_id */}
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.company_id}
                                  size="small"
                                  name="company_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("company_id", newValue)
                                      : setFieldValue("company_id", "")
                                  }
                                  options={companyList}
                                  getOptionLabel={(companyList) =>
                                    companyList.name || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Company"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        searchSpot("company", e.target.value)
                                      }
                                    />
                                  )}
                                />
                                {commaonError("company_id")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.position_id}
                                  size="small"
                                  name="position_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("position_id", newValue)
                                      : setFieldValue("position_id", "")
                                  }
                                  options={positionList}
                                  getOptionLabel={(positionList) =>
                                    positionList.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Position"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("position_id")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.source_of_lead_type}
                                  size="small"
                                  name="source_of_lead_type"
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setFieldValue(
                                        "source_of_lead_type",
                                        newValue
                                      );
                                    } else {
                                      setFieldValue("source_of_lead_type", "");
                                    }
                                    setFieldValue("source_of_lead_id", "");
                                  }}
                                  options={leadOptions}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Type of Lead Source"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("source_of_lead_type")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.source_of_lead_id}
                                  size="small"
                                  name="source_of_lead_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue(
                                          "source_of_lead_id",
                                          newValue
                                        )
                                      : setFieldValue("source_of_lead_id", "")
                                  }
                                  options={
                                    values.source_of_lead_type?.value === "ra"
                                      ? raList
                                      : values.source_of_lead_type?.value ===
                                        "applicant"
                                      ? applicantList
                                      : values.source_of_lead_type?.value ===
                                        "user"
                                      ? usersList
                                      : companyList
                                  }
                                  getOptionLabel={(paymentOptions) => {
                                    if (
                                      values.source_of_lead_type?.value === "ra"
                                    ) {
                                      return paymentOptions?.ra_name || "";
                                    } else if (
                                      values.source_of_lead_type?.value ===
                                        "applicant" ||
                                      values.source_of_lead_type?.value ===
                                        "user"
                                    ) {
                                      return paymentOptions?.first_name
                                        ? paymentOptions?.first_name +
                                            " " +
                                            paymentOptions?.last_name
                                        : "";
                                    } else {
                                      return paymentOptions?.name || "" || "";
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Name of Lead Source"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        searchSpot(
                                          values.source_of_lead_type?.value,
                                          e.target.value
                                        )
                                      }
                                    />
                                  )}
                                />
                                {commaonError("source_of_lead_id")}
                              </MDBox>
                            </Grid>

                            {/* Address */}
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="address"
                                  type="text"
                                  label="Address"
                                  fullWidth
                                  value={values.address}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("address")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="landmark"
                                  type="text"
                                  label="Landmark"
                                  fullWidth
                                  value={values.landmark}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("landmark")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="postal_code"
                                  type="text"
                                  label="Postal Code"
                                  fullWidth
                                  value={values.postal_code}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("postal_code")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="city"
                                  type="text"
                                  label="City"
                                  fullWidth
                                  value={values.city}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("city")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="state"
                                  type="text"
                                  label="State"
                                  fullWidth
                                  value={values.state}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("state")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.country_id}
                                  size="small"
                                  name="country_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("country_id", newValue)
                                      : setFieldValue("country_id", "")
                                  }
                                  options={countryList}
                                  getOptionLabel={(countryList) =>
                                    countryList.name || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Country"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("country_id")}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>

                {/* START Deployment section */}
                <Card className="mt4rem">
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Deployment Details
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="deployment_visa_issue_date"
                                  type="date"
                                  label="Visa Issue Date"
                                  fullWidth
                                  value={values.deployment_visa_issue_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("deployment_visa_issue_date")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="deployment_visa_expiry_date"
                                  type="date"
                                  label="Visa Expiry Date"
                                  disabled={values.deployment_date}
                                  fullWidth
                                  value={values.deployment_visa_expiry_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("deployment_visa_expiry_date")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="deployment_date"
                                  type="date"
                                  label="Deployment Date"
                                  disabled={values.deployment_visa_expiry_date}
                                  fullWidth
                                  value={values.deployment_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("deployment_date")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.deployment_type_id}
                                  size="small"
                                  name="deployment_type_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue(
                                          "deployment_type_id",
                                          newValue
                                        )
                                      : setFieldValue("deployment_type_id", "")
                                  }
                                  options={deploymentTypeList}
                                  getOptionLabel={(deploymentTypeList) =>
                                    deploymentTypeList.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Deployment Status *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("deployment_type_id")}
                              </MDBox>
                            </Grid>

                            <Grid item container xs={12} md={3} spacing={0}>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2} className="mdsAutocompleteGrid">
                                  <Autocomplete
                                    value={values.deployment_fine_currency_id}
                                    size="small"
                                    name="deployment_fine_currency_id"
                                    onChange={(event, newValue) =>
                                      newValue
                                        ? setFieldValue(
                                            "deployment_fine_currency_id",
                                            newValue
                                          )
                                        : setFieldValue(
                                            "deployment_fine_currency_id",
                                            ""
                                          )
                                    }
                                    options={currencyList}
                                    getOptionLabel={(currencyList) =>
                                      currencyList.currency_code || ""
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Currency"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    )}
                                  />
                                  {commaonError("deployment_fine_currency_id")}
                                </MDBox>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2}>
                                  <MDInput
                                    name="deployment_fine_payment"
                                    type="text"
                                    label="Fine Payment"
                                    fullWidth
                                    value={
                                      values.deployment_fine_payment > 0
                                        ? values.deployment_fine_payment
                                        : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {commaonError("deployment_fine_payment")}
                                </MDBox>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <MDBox mb={2}>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Remarks"
                                  name="deployment_remarks"
                                  InputLabelProps={{ shrink: true }}
                                  multiline
                                  fullWidth
                                  rows={3}
                                  value={values.deployment_remarks}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("deployment_remarks")}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>
                {/* END Deployment Section */}

                {/* Start Document Management */}
                <Card className="mt4rem">
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Document Management
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      onClick={() => addMoreDocument()}
                      color="white"
                      size="small"
                    >
                      Add Document
                    </MDButton>
                  </MDBox>

                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            {documents.map((val, i) => (
                              <Grid
                                item
                                className="borderBottom"
                                style={{
                                  borderBottom: "1px solid #33333338",
                                  marginBottom: "10px",
                                }}
                                container
                                xs={12}
                                md={12}
                                key={i}
                                spacing={2}
                              >
                                <Grid item xs={12} md={3}>
                                  <MDBox mb={2}>
                                    <MDInput
                                      name="name"
                                      type="text"
                                      required
                                      fullWidth
                                      value={val.name}
                                      onChange={(e) =>
                                        handleDocumentChange(e, i)
                                      }
                                      label={`Document Name`}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <MDBox className="mdsAutocompleteGrid">
                                    <Autocomplete
                                      size="small"
                                      name="issue_country_id"
                                      required
                                      value={val.issue_country_id}
                                      onChange={(e, newValue) => {
                                        const issueCountryId = [...documents];
                                        if (newValue) {
                                          issueCountryId[i][
                                            "issue_country_id"
                                          ] = newValue;
                                          setDocuments(issueCountryId);
                                        } else {
                                          issueCountryId[i][
                                            "issue_country_id"
                                          ] = null;
                                          setDocuments(issueCountryId);
                                        }
                                      }}
                                      options={countryList}
                                      getOptionLabel={(countryList) =>
                                        countryList.name || ""
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Issue Country"
                                          InputLabelProps={{ shrink: true }}
                                        />
                                      )}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <MDBox mb={2}>
                                    <MDInput
                                      type="date"
                                      name="issue_date"
                                      label="Issue Date"
                                      fullWidth
                                      value={val.issue_date}
                                      onChange={(e) =>
                                        handleDocumentChange(e, i)
                                      }
                                    />
                                  </MDBox>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                  <MDBox mb={2}>
                                    <MDInput
                                      type="date"
                                      name="expiry_date"
                                      label="Expiry Date"
                                      fullWidth
                                      value={val.expiry_date}
                                      onChange={(e) =>
                                        handleDocumentChange(e, i)
                                      }
                                    />
                                  </MDBox>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                  <MDBox
                                    mb={2}
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      //margin: "0px 2px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      fullWidth
                                      color="success"
                                    >
                                      Upload Document
                                      <input
                                        type="file"
                                        name="document"
                                        accept="image/png, image/jpeg, application/pdf"
                                        hidden
                                        onChange={(e) =>
                                          handleDocumentChange(e, i)
                                        }
                                      />
                                    </Button>
                                    {val.document && (
                                      <>
                                        <Link
                                          to={`${process.env.REACT_APP_URI_PATH}${val.document}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="mdsViewFile"
                                        >
                                          <MDButton
                                            variant="gradient"
                                            color="primary"
                                            size="small"
                                            type="button"
                                          >
                                            View File
                                          </MDButton>
                                        </Link>
                                      </>
                                    )}
                                  </MDBox>
                                </Grid>

                                <Grid item xs={12} md={2}>
                                  <IconButton className="mdsRemoveBtn">
                                    <DeleteForeverIcon
                                      variant="gradient"
                                      color="error"
                                      type="button"
                                      fontSize="large"
                                      onClick={() => handleDocumentDelete(i)}
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>
                {/* End Document Management */}
              </Grid>
            </Grid>
            <MDBox mt={2} display="flex" alignItems="center">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                type="submit"
                className="saveButton"
              >
                Save
              </MDButton>
            </MDBox>
          </Form>
        </Formik>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateApplicant;
