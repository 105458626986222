import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useAuth } from "utils/auth";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
import * as Yup from "yup";
import { updateData } from "utils/clientFunctions";
import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";
import { postData, doUpload } from "utils/clientFunctions";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import { useNavigate, useParams, Navigate, Link } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import { RegisterSchema } from "../register/scheme";
import { removeDuplicates } from "utils/clientFunctions";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "layouts/authentication/components/Footer";
import { useToast } from "UI/snakToast/ToastProvider";
function Basic() {
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [documents, setDocuments] = useState([]); //Manage multiple documents
  const { showToast } = useToast();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    email_id: "",
    phone_code_id: "",
    phone_number: "",
    upload_document: "",
    nationality_id: "",
    qualification: "",
    address: "",
  });

  const commaonError = (fieldName) => {
    return errors?.[fieldName] && touched?.[fieldName] ? (
      <p className="formError">{errors[fieldName]}</p>
    ) : null;
  };
  const getFetchData = async (url) => {
    return await fetchData(url);
  };

  const handleDocumentChange = async (event) => {
    let name = event.target.name;
    let val = event.target.files[0];
    let uploadFile = { file: val };
    const url = `upload_any/do_upload_file`;
    const response = await doUpload(url, uploadFile);
    if (response.status && response.uploadedFile) {
      let filePath = response.uploadedFile.path;
      //setFormData({ ...formData, [name]: filePath });
      formData.upload_document = filePath;
      setFieldValue("upload_document", filePath);
    } else {
      formData.upload_document = "";
      setFieldValue("upload_document", "");
      setFieldError("upload_document", response.message);
    }
  };

  const { login } = useAuth();

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: RegisterSchema,
    onSubmit: (values, action) => {
      handleFormSubmit(values, action);
    },
  });

  useEffect(() => {
    //Get countries list
    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      let countryList = res.data;
      setCountryList(countryList);
    });
  }, []);

  const handleFormSubmit = async (data, resetForm) => {
    let submitData = {};
    submitData = data;

    submitData.nationality_id = data.nationality_id?.country_id;
    submitData.phone_code_id = data.phone_code_id?.country_id;

    const response = await postData(`register`, submitData);

    if (!response.status) {
      if (response.message) {
        showToast(response.message, "error");
      }
      if (response.errors) {
        let apiErrors = response.errors;
        console.log("apiErrors", apiErrors);
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          showToast(errorMessage, "error");
        }
      }
    } else {
      showToast("Registration has been completed successfully", "success");

      setFormData({
        first_name: "",
        last_name: "",
        date_of_birth: "",
        email_id: "",
        phone_code_id: "",
        phone_number: "",
        upload_document: "",
        nationality_id: "",
        qualification: "",
        address: "",
      });
    }
  };

  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            bgImage &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%">
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid
            item
            xs={11}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            style={{ marginTop: "3rem" }}
          >
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Register
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <Formik
                  initialValues={formData}
                  enableReinitialze={true}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <Grid container spacing={12}>
                      <Grid item xs={12}>
                        <Card>
                          <MDBox
                            variant="contained"
                            bgColor="white"
                            color="black"
                            borderRadius="md"
                            shadow="md"
                          >
                            <DialogContent>
                              <Box>
                                <MDBox pt={1} pb={1}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          type="text"
                                          name="first_name"
                                          label="First Name"
                                          fullWidth
                                          value={values.first_name}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        {commaonError("first_name")}
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          type="text"
                                          name="last_name"
                                          label="Last Name"
                                          fullWidth
                                          value={values.last_name}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        {commaonError("last_name")}
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="date_of_birth"
                                          type="date"
                                          label="Date of Birth"
                                          fullWidth
                                          value={values.date_of_birth}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        {commaonError("date_of_birth")}
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="email_id"
                                          type="email"
                                          label="E-mail Id"
                                          fullWidth
                                          value={values.email_id}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        {commaonError("email_id")}
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <MDBox
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          margin: "0px 2px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          component="label"
                                          fullWidth
                                          color="success"
                                        >
                                          Upload Resume
                                          <input
                                            type="file"
                                            name="upload_document"
                                            accept="image/png, image/jpeg, application/pdf"
                                            hidden
                                            onChange={(e) =>
                                              handleDocumentChange(e)
                                            }
                                          />
                                        </Button>
                                        {formData.upload_document && (
                                          <Link
                                            to={
                                              process.env.REACT_APP_URI_PATH +
                                              formData.upload_document
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="mdsViewFile"
                                          >
                                            <MDButton
                                              variant="gradient"
                                              color="primary"
                                              size="small"
                                              type="button"
                                            >
                                              View File
                                            </MDButton>
                                          </Link>
                                        )}
                                      </MDBox>
                                      {commaonError("upload_document")}
                                    </Grid>

                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      md={6}
                                      spacing={0}
                                    >
                                      <Grid item xs={6} md={6}>
                                        <MDBox
                                          mb={2}
                                          className="mdsAutocompleteGrid"
                                        >
                                          <Autocomplete
                                            value={values.phone_code_id}
                                            size="small"
                                            name="phone_code_id"
                                            onChange={(event, newValue) =>
                                              newValue
                                                ? setFieldValue(
                                                    "phone_code_id",
                                                    newValue
                                                  )
                                                : setFieldValue(
                                                    "phone_code_id",
                                                    ""
                                                  )
                                            }
                                            options={countryList}
                                            getOptionLabel={(countryList) => {
                                              if (
                                                countryList.dial_code &&
                                                countryList.name
                                              ) {
                                                return (
                                                  countryList.dial_code +
                                                  " " +
                                                  countryList.name
                                                );
                                              }
                                              return "";
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Country Code"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            )}
                                          />
                                          {commaonError("phone_code_id")}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={6} md={6}>
                                        <MDBox mb={2}>
                                          <MDInput
                                            name="phone_number"
                                            type="text"
                                            label="Mobile Number"
                                            fullWidth
                                            value={values.phone_number}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                          {commaonError("phone_number")}
                                        </MDBox>
                                      </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                      <MDBox
                                        mb={2}
                                        className="mdsAutocompleteGrid"
                                      >
                                        <Autocomplete
                                          value={values.nationality_id}
                                          size="small"
                                          name="nationality_id"
                                          onChange={(event, newValue) =>
                                            newValue
                                              ? setFieldValue(
                                                  "nationality_id",
                                                  newValue
                                                )
                                              : setFieldValue(
                                                  "nationality_id",
                                                  ""
                                                )
                                          }
                                          options={countryList}
                                          getOptionLabel={(countryList) =>
                                            countryList.name || ""
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Nationality"
                                              InputLabelProps={{ shrink: true }}
                                            />
                                          )}
                                        />
                                        {commaonError("nationality_id")}
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          type="text"
                                          name="qualification"
                                          label="Qualification"
                                          fullWidth
                                          value={values.qualification}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        {commaonError("qualification")}
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          type="text"
                                          name="address"
                                          label="Address"
                                          fullWidth
                                          value={values.address}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        {commaonError("address")}
                                      </MDBox>
                                    </Grid>
                                  </Grid>
                                </MDBox>
                              </Box>
                            </DialogContent>
                          </MDBox>
                        </Card>
                      </Grid>
                    </Grid>
                    <MDBox mt={2} display="flex" alignItems="center">
                      <MDButton
                        variant="gradient"
                        color="primary"
                        size="small"
                        type="submit"
                        className="saveButton"
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </Form>
                </Formik>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

export default Basic;
