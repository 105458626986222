// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState } from "react";
//import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
//import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import * as Yup from "yup";
import { updateData } from "utils/clientFunctions";

import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";

import { postData, doUpload } from "utils/clientFunctions";
import { ToastProvider, useToasts } from "react-toast-notifications";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import { useNavigate, useParams, Navigate, Link } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";

import { interviewSchema } from "../components/schemas";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function SaveInterview() {
  const { id: interviewId } = useParams();

  const [countryList, setCountryList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const [locations, setLocations] = useState([]); //Manage multiple locations

  const { addToast } = useToasts();
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  if (interviewId) {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("intEdit")
      )
    ) {
      navigate("/interview_list");
    }
  } else {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("intCreate")
      )
    ) {
      navigate("/interview_list");
    }
  }
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [formData, setFormData] = useState({
    area: "",
    //interview_date_time: "",
    company_id: "",
    //position_id: "",
    remarks: "",
    status_id: "",
    start_date: "",
    end_date: "",
    location: "",
    interviewer: "",
    total_selections: "",
    deployed_number: "",
    unavailable: "",
    positions: [],
  });

  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setCompanyList,
    });
  };

  const getFetchData = async (url) => {
    return await fetchData(url);
  };

  //Get applicant, docs, religions, countries, position list and size of the company list
  useEffect(() => {
    if (interviewId) {
      const getcallReportUrl = `interview/${interviewId}`;
      getFetchData(getcallReportUrl).then((res) => {
        if (res.data) {
          let {
            area,
            //interview_date_time,
            remarks,
            company_info,
            //position_info,
            status_info,
            start_date,
            end_date,
            location,
            interviewer,
            total_selections,
            deployed_number,
            unavailable,
            positions_list,
            locations_list,
          } = res.data;

          let updatedPositionsList = [];

          if (positions_list) {
            positions_list?.map((position) => {
              updatedPositionsList.push(position.position_info);
            });
          }

          setFormData({
            area,
            // interview_date_time:
            //   interview_date_time &&
            //   new Date(interview_date_time).toLocaleString("sv").slice(0, 16),
            remarks,
            company_id: company_info,
            // position_id: position_info,
            status_id: status_info,
            start_date: start_date,
            end_date: end_date,
            location: location,
            interviewer: interviewer,
            total_selections: total_selections,
            deployed_number: deployed_number,
            unavailable: unavailable,
            positions: updatedPositionsList,
          });

          let locationsList = [];
          locations_list?.map((locationItem) => {
            const { location } = locationItem;
            locationsList.push({
              name: location,
            });
          });
          setLocations(locationsList);
        }
      });
    }

    const companyUrl = `company/list?page=1`;
    getFetchData(companyUrl).then((res) => {
      let companyList = res.data;
      setCompanyList(companyList);
    });

    //Get countries list
    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      let countryList = res.data;
      setCountryList(countryList);
    });

    //Get position List
    const positionUrl = `setting/type/4`;
    getFetchData(positionUrl).then((res) => {
      let positionListResult = res.data;
      setPositionList(positionListResult);
    });

    //Get status list
    const statusUrl = `setting/type/8`;
    getFetchData(statusUrl).then((res) => {
      let statusListResult = res.data;
      setStatusList(statusListResult);
    });
  }, []);

  const handleFormSubmit = async (data, resetForm) => {
    //Collect final Data
    let submitData = {};
    submitData = data;

    //Update below fields to send only ids
    submitData.company_id = data.company_id?.company_id;
    //submitData.position_id = data.position_id?.setting_id;
    submitData.status_id = data.status_id?.setting_id;
    submitData.location = data.area == "Local Area" ? data.location : "";

    // Convert attendees(Users) list into array
    let allPositions = [];
    if (data.positions.length) {
      data?.positions?.map((position) => {
        allPositions.push(position?.setting_id);
      });
    }
    submitData.positions = allPositions;

    //Convert locations into array
    let allLocations = [];
    if (locations.length) {
      locations?.map((locationItem) => {
        const { name } = locationItem;
        if (name) {
          allLocations.push({
            location: name,
          });
        }
      });
    }
    submitData.location_list = allLocations;

    console.log("submitData", submitData);

    const interviewUrl = `interview`;
    const response = interviewId
      ? await updateData(`${interviewUrl}/${interviewId}`, submitData)
      : await postData(interviewUrl, submitData);

    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      let successMessage = interviewId
        ? "Interview has been updated successfully"
        : "New interview has been created successfully";

      addToast(successMessage, {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(-1);
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: interviewSchema,
    onSubmit: (values, action) => {
      handleFormSubmit(values, action);
    },
  });

  console.log("values", values);
  console.log("errors", errors);

  const commaonError = (fieldName) => {
    return errors?.[fieldName] && touched?.[fieldName] ? (
      <p className="formError">{errors[fieldName]}</p>
    ) : null;
  };

  // START Manage Document
  const addMoreLocation = () => {
    setLocations([...locations, {}]);
  };
  const handleLocationChange = async (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...locations];
    onchangeVal[i][name] = value;
    setLocations(onchangeVal);
  };
  const handleDocumentDelete = (i) => {
    const deleteVal = [...locations];
    deleteVal.splice(i, 1);
    setLocations(deleteVal);
  };
  // END Manage Document
  console.log("locations", locations);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Formik
          initialValues={formData}
          enableReinitialze={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      {`${interviewId ? "Update" : "New"} `} interview
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      color="white"
                      size="small"
                    >
                      Back
                    </MDButton>
                  </MDBox>
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                              <MDBox mb={2}>
                                <label className="mdsInlineLabel">
                                  Select area
                                </label>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="area"
                                    name="area"
                                    value={values.area}
                                    onChange={handleChange}
                                  >
                                    <FormControlLabel
                                      value="Local Area"
                                      size="small"
                                      control={<Radio />}
                                      label="Local Area"
                                    />
                                    <FormControlLabel
                                      value="Overseas"
                                      size="small"
                                      control={<Radio />}
                                      label="Overseas"
                                    />
                                  </RadioGroup>
                                </FormControl>
                                {commaonError("area")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="start_date"
                                  type="date"
                                  label="Start Date"
                                  fullWidth
                                  value={values.start_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("start_date")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="end_date"
                                  type="date"
                                  label="End Date"
                                  fullWidth
                                  value={values.end_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("end_date")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  name="interviewer"
                                  label="Interviewer"
                                  fullWidth
                                  value={values.interviewer}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("interviewer")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="number"
                                  name="total_selections"
                                  label="Total Selections"
                                  fullWidth
                                  value={values.total_selections}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("total_selections")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="number"
                                  name="deployed_number"
                                  label="Deployed Number"
                                  fullWidth
                                  value={values.deployed_number}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("deployed_number")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="number"
                                  name="unavailable"
                                  label="Unavailable"
                                  fullWidth
                                  value={values.unavailable}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("unavailable")}
                              </MDBox>
                            </Grid>

                            {/*<Grid item xs={12} md={4}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="interview_date_time"
                                  type="datetime-local"
                                  label="Date Time"
                                  fullWidth
                                  value={values.interview_date_time}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("interview_date_time")}
                              </MDBox>
                            </Grid> */}

                            <Grid item xs={12} md={4}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.company_id}
                                  size="small"
                                  name="company_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("company_id", newValue)
                                      : setFieldValue("company_id", "")
                                  }
                                  options={companyList}
                                  getOptionLabel={(companyList) =>
                                    companyList.name || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Company"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        searchSpot("company", e.target.value)
                                      }
                                    />
                                  )}
                                />
                                {commaonError("company_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.status_id}
                                  size="small"
                                  name="status_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("status_id", newValue)
                                      : setFieldValue("status_id", "")
                                  }
                                  options={statusList}
                                  getOptionLabel={(statusList) =>
                                    statusList.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Status"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("status_id")}
                              </MDBox>
                            </Grid>

                            {/* <Grid item xs={12} md={4}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.position_id}
                                  size="small"
                                  name="position_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("position_id", newValue)
                                      : setFieldValue("position_id", "")
                                  }
                                  options={positionList}
                                  getOptionLabel={(positionList) =>
                                    positionList.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Position"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("position_id")}
                              </MDBox>
                            </Grid> */}

                            <Grid item xs={12} md={8}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  multiple
                                  value={values.positions}
                                  id="checkboxes-tags-demo"
                                  size="small"
                                  name="positions"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("positions", newValue)
                                      : setFieldValue("positions", "")
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option.setting_id === value.setting_id
                                  }
                                  options={positionList}
                                  disableCloseOnSelect
                                  getOptionLabel={(option) =>
                                    option.setting_value || ""
                                  }
                                  renderOption={(
                                    props,
                                    option,
                                    { selected }
                                  ) => (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.setting_value}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Positions"
                                      placeholder="Select Positions"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                              </MDBox>
                            </Grid>

                            {values.area == "Local Area" && (
                              <Grid item xs={12} md={4}>
                                <MDBox mb={2}>
                                  <MDInput
                                    type="text"
                                    name="location"
                                    label="Location"
                                    fullWidth
                                    value={values.location}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {commaonError("location")}
                                </MDBox>
                              </Grid>
                            )}

                            <Grid item xs={12} md={12}>
                              <MDBox mb={2}>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Remarks *"
                                  name="remarks"
                                  InputLabelProps={{ shrink: true }}
                                  multiline
                                  fullWidth
                                  rows={3}
                                  value={values.remarks}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("remarks")}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>

                {/* Start Location Management */}
                {values.area == "Overseas" && (
                  <Card className="mt4rem">
                    <MDBox
                      mx={2}
                      mt={-5}
                      py={1}
                      borderRadius="lg"
                      px={2}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      variant="gradient"
                      bgColor="info"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Location Management
                      </MDTypography>
                      <MDButton
                        variant="outlined"
                        onClick={() => addMoreLocation()}
                        color="white"
                        size="small"
                      >
                        Add Location
                      </MDButton>
                    </MDBox>

                    <MDBox
                      variant="contained"
                      bgColor="white"
                      color="black"
                      borderRadius="md"
                      shadow="md"
                    >
                      <DialogContent>
                        <Box sx={{ flexGrow: 1 }}>
                          <MDBox pt={1} pb={1}>
                            <Grid container spacing={2}>
                              {locations.map((val, i) => (
                                <Grid
                                  item
                                  className="borderBottom"
                                  style={{
                                    borderBottom: "1px solid #33333338",
                                    marginBottom: "10px",
                                  }}
                                  container
                                  xs={12}
                                  md={12}
                                  key={i}
                                  spacing={2}
                                >
                                  <Grid item xs={12} md={3}>
                                    <MDBox mb={2}>
                                      <MDInput
                                        name="name"
                                        type="text"
                                        required
                                        fullWidth
                                        value={val.name}
                                        onChange={(e) =>
                                          handleLocationChange(e, i)
                                        }
                                        label={`Location Name`}
                                      />
                                    </MDBox>
                                  </Grid>

                                  <Grid item xs={12} md={2}>
                                    <IconButton className="mdsRemoveBtn">
                                      <DeleteForeverIcon
                                        variant="gradient"
                                        color="error"
                                        type="button"
                                        fontSize="large"
                                        onClick={() => handleDocumentDelete(i)}
                                      />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          </MDBox>
                        </Box>
                      </DialogContent>
                    </MDBox>
                  </Card>
                )}
                {/* End Location Management */}
              </Grid>
            </Grid>
            <MDBox mt={2} display="flex" alignItems="center">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                type="submit"
                className="saveButton"
              >
                Save
              </MDButton>
            </MDBox>
          </Form>
        </Formik>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SaveInterview;
