import * as Yup from "yup";

export const interviewSchema = Yup.object({
  //   application_name: Yup.string()
  //     .min(2, "Please enter minimum two characters")
  //     .required("Please enter applicant name")
  //     .max(250, "Max 250 characters"),
  area: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please select area")
    .max(120),
  // interview_date_time: Yup.date().required(
  //   "Please provide interview date time"
  // ),
  company_id: Yup.mixed().required("Please select company"),
  //position_id: Yup.mixed().required("Please select position"),
  //outcome: Yup.string().min(2, "Please enter minimum two characters").required("Please enter outcome").max(250, "Max 250 characters"),
  status_id: Yup.mixed().required("Please select status"),
  remarks: Yup.string()
    .min(2, "Please enter minimum 2 characters")
    .required("Please enter remarks")
    .max(250, "Max 250 characters allowed"),

  start_date: Yup.date().required("Please provide interview date time"),
  end_date: Yup.date().required("Please provide interview date time"),

  location: Yup.mixed().when("area", (value) => {
    console.log("Area value :", value);
    if (value[0] == "Local Area") {
      return Yup.string()
        .min(2, "Please enter minimum two characters")
        .required("Please enter location")
        .max(250);
    } else {
      return Yup.mixed()
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional();
    }
  }),

  interviewer: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter interviewer")
    .max(250),
  total_selections: Yup.number()
    .typeError("Please enter total selections")
    .required("Please enter total selections"),
  deployed_number: Yup.number()
    .typeError("Please enter deployed number")
    .required("Please enter deployed number"),
  unavailable: Yup.number()
    .typeError("Please enter unavailable")
    .required("Please enter unavailable"),
});
